import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui';
import router from '../route'

const instance = axios.create({
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
})

// 请求拦截器
instance.interceptors.request.use(
  config => {
    let isProduction = process.env.NODE_ENV === 'production'
    if (!isProduction) {
      // 开发模式
      config.data = { ...config.data, whosyourdaddy: 3033 }
    }
    if (typeof config.data === 'object') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code !== 1000) {
      if (response.data.code && +response.data.code === 1500) {
        window.location.href = 'https://amoreplus.amorepacific.com.cn/traningVote2021Login/index'
      } else {
        Message.error(response.data.data)
        router.replace('/')
        return Promise.reject(response.data.data)
      }
    }
    return response.data
  },
  error => {
    Message.error(error)
    router.replace('/')
    return Promise.reject(error)
  }
)

export default instance
